import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';

import styles from './footer.module.scss';

const Footer = () => (
  <footer className={styles.footer}>
    <Logo className={styles.footer__logo} src={Logo} alt="Logo" />
  </footer>
);

export default Footer;
