import React from 'react';
import useFetchWeather from 'components/hooks/useFetchWeather';

import Logo from 'components/logo/logo';
import Clock from 'components/clock/clock';
import Weather from 'components/weather/weather';
import Footer from 'components/footer/footer';

import GlobalStyle from '../theme/GlobalStyle';
import styles from './App.module.scss';

const App = () => {
  const { weatherData } = useFetchWeather();

  return (
    <>
      <GlobalStyle />
      <div className={styles.wrapper}>
        <Logo />
        <div className={styles.border} />
        <Clock />
        <div className={styles.border} />
        <Weather weatherData={weatherData} />
        <div className={styles.border} />
        <Footer />
      </div>
    </>
  );
};

export default App;
